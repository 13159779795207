import { Link, graphql } from "gatsby";
import styled from "@emotion/styled";
import React from "react";
import MainLayout from "../components/MainLayout";
import breakpoints from "../utils/breakpoints";

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          excerpt
          frontmatter {
            date
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

type SummaryFields = {
  excerpt: string;
  frontmatter: {
    date: string;
    title: string;
  };
  fields: {
    slug: string;
  };
};

type FileQuery = {
  allMarkdownRemark: {
    edges: {
      node: SummaryFields;
    }[];
  };
};

type Props = {
  data: FileQuery;
};

const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: 7rem 1fr;
  grid-template-rows: auto auto;
  column-gap: 5px;
  row-gap: 5px;
  align-items: center;
  margin-bottom: 1.7rem;
  grid-template-areas:
    "date title"
    "excerpt excerpt";
  @media ${breakpoints.mobileL} {
    grid-template-areas:
      "date title"
      "date excerpt";
  }
`;

const ShortSummary = (data: SummaryFields) => (
  <SummaryContainer key={data.fields.slug}>
    <div style={{ gridArea: "date" }}>{data.frontmatter.date}</div>
    <div style={{ gridArea: "title" }}>
      <Link to={data.fields.slug}>{data.frontmatter.title}</Link>
    </div>
    <div style={{ gridArea: "excerpt" }}>{data.excerpt}</div>
  </SummaryContainer>
);

const ShortSummaryStyled = styled(ShortSummary)``;

export default function ({ data }: Props) {
  return (
    <MainLayout title={null}>
      <div>
        {data.allMarkdownRemark.edges.map((edge) => (
          <ShortSummaryStyled {...edge.node} />
        ))}
      </div>
    </MainLayout>
  );
}
